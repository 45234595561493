/**
 * @description vue状态管理
 */

import store from "vuex"
import Vue from "vue"

Vue.use(store)

export default new store.Store({
    state: {
        //路由切换状态
        rc: false,
        // 登录状态
        loginState: false,
        isDetialFous: false,

        selectPart: "",
        // 用户信息
        userInfo: {

        },
        nowTab: "Features",
        isLoading: false,
        threeLoad: false,//，模型加载
        isDisabled: true,//是否可点击
        selectMesh: 'LFP_9kWhHV',
        selectName: "LFP9-30kWh/HV·LFP 9kWh/HV",
        meshType: "LFP9-30kWh/HV",
        isStructure: false,
        isDown: false,
    },
    mutations: {
    },
    actions: {
    }
})
